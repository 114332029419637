import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
  selector: 'logibids-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  dropdown: boolean;
  privacy: boolean;
  language = 'en';
  lan = localStorage.getItem('language') || 'en';
  languageConstants = languageConstants;
  languageKey = languageConstants[this.lan];


  constructor(
    private messageService: MessageService,
    private router: Router
  ) {
    const lang = localStorage.getItem('language');
    if (lang) {
      this.language = lang;
      this.languageKey = this.languageConstants[lang]
    }
  }

  ngOnInit() {
    this.lan = localStorage.getItem('language') || 'en';
  }

  showNav() {
    this.dropdown = !this.dropdown;
    this.privacy = false;
  }

  setLanguage(language) {
    console.log(language,'MY KANNDDFDFDF')
    const url = document.URL.split('/');
    url[3] = language;
    window.location.href = url.join('/');
  }

  showPrivacy() {
    this.privacy = !this.privacy;
    this.dropdown = false;
  }

  gotoPrivacy() {
    this.router.navigate(['/public/privacy']);
  }

  gotoTerms() {
    this.router.navigate(['/public/terms']);
  }

  gotoFaq() {
    this.router.navigate(['/public/faq']);
  }
  gotoContact() {
    this.router.navigate(['/public/contact']);
  }
  gotoContract() {
    this.router.navigate(['/public/contract']);
  }


  addSingle() {
    this.messageService.add({severity: 'success', summary: 'Service Message', detail: 'Via MessageService'});
  }

  gotoLogin() {
    this.router.navigate(['/login']);
  }
}
