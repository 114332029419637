import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { error } from 'protractor';
import { languageConstants } from 'src/app/constants/languageConstants';

@Component({
    selector: 'google-location-selector',
    templateUrl: './google-location-selector.html',
    styleUrls: ['./google-location-selector.css']
})
export class GoogleLocationSelectorComponent implements OnInit {

    title = 'AGM project';

    latitude: number;
    language = 'en';
    lan = localStorage.getItem('language') || 'en';
    languageConstants = languageConstants;
    languageKey = languageConstants[this.lan];

    longitude: number;
    zoom = 8;

    address: string;

    private geoCoder;

    @ViewChild('search', { static: false }) searchElementRef: ElementRef;

    @Output() setAddress: EventEmitter<any> = new EventEmitter();

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) {
        const lang = localStorage.getItem('language');
        if (lang) {
          this.language = lang;
          this.languageKey = this.languageConstants[lang]
        }
     }


    ngOnInit() {
        // load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['address']
            });
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }
                    // set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                    this.getAddress(this.latitude, this.longitude);
                });
            });
        });
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude);
            }, (error) => {
                console.log('Location error : ', error);
            });
        } else {
            console.log('Failed load location . . .');
        }
    }


    markerDragEnd($event: MouseEvent) {
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                    this.invokeEvent({ location: { lat: latitude, lng: longitude }, address: this.address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }
    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }
}
