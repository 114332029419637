export const languageConstants = {
  ar: {
    SELECTVEHICLE: "اختر مركبة",
    RATINGREVIEW: "التقييم والتعليق",
    MYRATING: "تقييماتي",
    NONOTIFICATION: "لم يتم العثور على أي إشعار!",
    BRAND: "نوع العلامة التجارية",
    MODEL: "موديل المركبة",
    IMMEDIATE: "مباشر",
    SCHEDULE: "جدول",
    WIDTH: "عرض",
    HEIGHT: "وزن",
    LENGTH: "طول",
    ADDRESSTYPE: "نوع العنوان",
    AREA: "منطقة",
    APARTMENTNO: "رقم الشقة",
    AVENUE: "شارع",
    BUILDINGNO: "لا للبناء",
    ADDITIONALINFO: "معلومات إضافية",
    addVehicleMessage1: "الرجاء إضافة المركبات أولا",
    rating: "التقييم",
    review: "التعليق",
    CHANGEPASSWORD: "تغيير كلمة المرور",
    noRating: "لا يوجد تقييم",
    oldPasswordMessage: "الرجاء إدخال كلمة المرور القديمة",
    newPasswordMessage: "الرجاء إدخال كلمة المرور الجديدة",
    newPasswordMinLengthMessage: "كلمة المرور الجديدة لا يمكن أن تكون أقل من 4 أحرف",
    confirmPasswordMessage: "الرجاء إدخال تأكيد كلمة المرور",
    newconfirmrequired: "الرجاء إدخال كلمة المرور الجديدة وتأكيد كلمة المرور",
    oldconfirmrequired: "الرجاء إدخال كلمة المرور القديمة وتأكيد كلمة المرور",
    oldnewPassword: "الرجاء إدخال كلمة المرور القديمة وكلمة المرور الجديدة",
    OLDPASSWORDNEWPASSWORD:
      "لا يمكن أن تكون كلمة المرور القديمة وكلمة المرور الجديدة متماثلتين",
    TYPEOFDELIVERY: "نوع التوصيل",
    ENTERLICENSENUMBER: "أدخل رقم الترخيص",
    PUBLIC: "نفل عام",
    GOODS: "نقل بضائع",
    ACTION: "فعل",
    ANOTHERACTION: "عمل آخر",
    SOMETHINGELESEHERE: "شيء آخر هنا",
    INTERNATIONALPERMIT: "تصريح دولي",
    SELECTPERMITREGION: "حدد منطقة السماح",
    VEHICLEEXISTS: "المركبة موجودة",
    VEHICLE: "المركبة",
    PLEASESELECTSUBCATEGORY: "الرجاء تحديد الفئة الفرعية",
    SUBCATEGORYISREQUIRED: "الفئة الفرعية مطلوبة",
    ENQUIRIES: "الطلبات",
    ENTERYOURPASSWORD: "ادخل رقمك السري",
    LOGIN: "تسجيل الدخول",
    CONFIRMPICKUP: "تأكيد الاستلام",
    SETYOURLOCATION: "حدد موقعك",
    LANGUAGE: "لغة",
    GETSTARTED: "البدء",
    ENTERPHONENUMBER: "أدخل رقم هاتفك",
    ENTERNEWPASSWORD: "أدخل كلمة المرور الجديدة",
    ENTERCODENEWPASSWORD: "أدخل الرمز الخاص بك وكلمة المرور الجديدة",
    FORGOTPASSWORD: "هل نسيت كلمة السر",
    COPYRIGHT: "حقوق النشر © 2010-2020 (خدمة النقل)",
    NEXT: "التالي",
    DAYS: "أيام",
    HOURS: "ساعات",
    VEHICLEREQUIRED: "مطلوب مركبة",
    BRANDREQUIRED: "العلامة التجارية مطلوبة",
    ADDBIDAMOUNT: "أضف مبلغ المزايدة",
    MINUTES: "دقائق",
    SUBMIT: "إرسال",
    ALLBRAND: "جميع العلامات التجارية",
    TRANSPORT: "النقل",
    RENTAL: "تأجير",
    ACCEPTTRIP: "قبول الرحلة",
    UPLOADCOMPANYLICENSE: "رخصة الشركة / رخصة القيادة",
    ACCEPTTERMSANDCONDITION: "يرجى قبول الشروط والأحكام",
    PICKUP: "استلام",
    DOCUMENTS: "مستندات",
    UPLOADYOURID: "رقم الهوية",
    DELIVER: "توصيل",
    REJECTTRIP: "رفض الرحلة",
    ADD: "إضافة",
    TRACKDRIVER: "تتبع السائق",
    BIDNOW: "المزايدة الآن",
    SHOWMAP: "عرض الخريطة",
    FROM: "من",
    TO: "إلى",
    CALL: "اتصل",
    NAME: "الاسم",
    CONTACT: "رقم الاتصال",
    CONTRACT: "عقد",
    EMAIL: "بريد إلكتروني",
    CUSTOMERRATING: "تقييم العملاء",
    AVERAGERATING: "متوسط ​​تقييم",
    PRIVACYPOLICY: "سياسة الخصوصية",
    TERMSANDCONDITIONS: "الأحكام والشروط",
    BIDFOR: "مزايدة ب",
    YOU: "أنت",
    HOURSPERDAY: "ساعات / يوم",
    NOOFTRIPS: "عدد الرحلات",
    TYPEOFVEHICLE: "نوع المركبة",
    GOTOTRIP: "اذهب إلى الرحلة",
    USERDETAILS: "تفاصيل العميل",
    CREATEBID: "إنشاء مزايدة",
    DRIVERDETAILS: "تفاصيل السائق",
    WEIGHTOFCOMMODITY: "وزن السلعة",
    DELIVERYCHARGE: "رسوم التوصيل",
    TOTALDISTANCE: "المسافة الكلية",
    PICKUPSCHEDULED: "وقت الاستلام",
    TYPEOFCOMMODITY: "حدد السلعة",
    REMAININGTIMEFORPICKUP: "الوقت المتبقي للاستلام",
    ENQUIRYDETAILPAGE: "صفحة تفاصيل الطلب",
    HOME: "الرئيسية",
    SIGNUP: "اشتراك",
    DONTRECIEVE: "لم يتم استلام رمز التحقق؟",
    YOURACCOUNT: "الحساب الخاص بك",
    ADDDRIVER: "إضافة سائق",
    ADDVEHICLE: "أضف مركبة",
    COMPLETEYOURPROFILE: "أكمل ملفك الشخصي",
    NOACTIVEBID: "لا يوجد مزايدة نشطه الان",
    YOURLASTBID: "مزايدتك الأخيرة",
    SEARCHENQUIRY: "بحث عن طلب",
    ENQUIRYENDS: "انتهى الطلب",
    NORECORDSFOUND: "لم  يتم العثور على طلب",
    AUCTIONS: "المزادات",
    DELIVERY: "توصيل",
    AGREECONTINUE: "الموافقة والمتابعة",
    RUNNING: "جاري التنفيذ",
    PENDING: "قيد الانتظار",
    ENDED: "انتهى",
    CANCEL: "إلغاء",
    CANCELED: "ألغيت",
    BIDDING_STARTED: "بدأ المزاد",
    HALF_TIME: "انتهى نصف الوقت",
    ENDING_SOON: "ستنتهى قريبا",
    BIDS: "مزايدة",
    WON: "ربحت",
    active: "نشط",
    inactive: "غير نشط",
    login_error_1: "الرجاء تقديم تفاصيل صحيحة!",
    Required: "*مطلوب",
    Invalid: "*غير صالحة",
    Invalid_PhnNumber: "* مطلوب رقم هاتف صالح",
    password_character:
      "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ، حرف واحد صغير ، حرف كبير واحد ، رقم واحد ، حرف خاص واحد",
    password_must: "* كلمة السر يجب ان تتطابق",
    forgot_pwd_msg: "الرجاء إدخال رقم هاتف صحيح !",
    upload: "رفع المستند",
    max: "الحد الأقصى للسائق هو 5",
    added_user: "إضاف مستخدم",
    unadded_user: "صارف کے بطور ڈرائیور کو غیر منتخب کریں۔",
    added: "السائقين المضافة!",
    something: "هناك خطأ ما",
    driver_message: "هل انت متأكد من انك تريد حذف المركبة ؟",
    acpt_lbl: "نعم",
    rjct_lbl: "لا",
    account_message: "هل أنت متأكد أنك تريد حذف حسابك؟",
    vehicle_message: "هل انت متأكد من انك تريد حذف المركبة ؟",
    Your: "عرض مزايدتك الأخيرة",
    driver_unavailable: "السائق غير متوفر!",
    Change: "تغيير السائق",
    Add: "اضافة",
    signup_succ: "تسجيل ناجح",
    confirm: "الرجاء الانتظار خمس ثوان للتحقق!",
    otp_sent: "تم إرسال OTP بنجاح",
    something_wrong: "هناك خطأ ما",
    enq_acpt: "تم قبول الطلب!",
    bid_amt: "مبلغ المزايدة مطلوب!",
    enter_amt: "الرجاء إدخال المبلغ أقل من العطاء الحالي",
    you_cant: "لايمكن تقديم مزايدات على التوالي",
    adding_bid: "إضافة مزايدة",
    succ_add: "أضيف بنجاح!",
    plz_slct_drvr: "الرجاء تحديد السائق!",
    success: "نجاح",
    enq_rjct: "تم رفض الطلب!",
    start_trip: "هل أنت متأكد أنك تريد أن تبدأ الرحلة؟",
    reject_trip: "هل أنت متأكد أنك تريد رفض الرحلة؟",
    replace_driver_self: "هل أنت متأكد أنك تريد تخصيص الرحلة لسائق آخر؟",
    rating_submit: "درجہ بندی کامیابی کے ساتھ جمع کرائی گئی۔",
    replace_driver_request: "هل أنت متأكد أنك تريد طلب بديل؟",
    plz_upload: "الرجاء تحميل جميع المستندات",
    plz_accpt: "يرجى تأكيد قبولك للشروط والأحكام",
    rmd_drvr: "تم ازالة السائق",
    dlt_vehicle: "هل أنت متأكد أنك تريد حذف هذه السيارة؟",
    rmd_vhcl: "المركبة التي تمت إزالتها",
    prf_updt: "تم تحديث الملف الشخصي",
    prf_pwd_updt: "تم تحديث كلمة السر",
    Driver: "سائق",
    Permits: "تصريح دولي",
    Agree: "يوافق على",
    ok: "حسنا!",
    KMSPERDAY: "كم / أيام",
    enquiry_del_msg: "هذا الطلب لم يعد متاحا",
    bids: "لا يوجد مزايدة",
    vehicles: "مركبات",
    NOOFDAYS: "عدد الأيام",
    totalKM: "كم/يوم",
    DRIVER: "سائق",
    FUEL: "وقود",
    SERVICE: "صيانة",
    passwordstr10:
      "يجب أن تتكون كلمة المرور الخاصة بك من 8 أحرف على الأقل وتتضمن حرفًا كبيرًا واحدًا وحرفًا صغيرًا واحدًا ورقمًا واحدًا وحرفًا خاصًا واحدًا على الأقل.",
    passwordstr1: "1) يجب أن تحتوي كلمة المرور الخاصة بك على 8 أحرف على الأقل.",
    passwordstr2:
      "2) يجب أن تحتوي كلمة مرورك على حرف واحد كبير أو كبير (على سبيل المثال: أ ، ب ، إلخ.)",
    passwordstr3: "3) يجب أن تحتوي كلمة مرورك على حرف صغير واحد على الأقل.",
    passwordstr4:
      "4) يجب أن تحتوي كلمة مرورك على رقم واحد على الأقل (على سبيل المثال: 0 ، 1 ، 2 ، 3 ، إلخ.)",
    passwordstr5:
      "5) يجب أن تحتوي كلمة مرورك على حرف خاص واحد على الأقل - على سبيل المثال: $، #، @،!،٪، ^، &، *، (،)",
    MYDOCUMENTS: "مستنداتي",
    MYVEHICLES: "مركباتي",
    MYDRIVERS: "السائقين",
    MYTRIPS: "رحلاتي",
    MYPROFILE: "ملفى",
    LOGOUT: "تسجيل خروج",
    WITHDRAW: "انسحب",
    DELETEACCOUNT: "حذف الحساب",
    SEARCHVEHICLES: "البحث عن مركبات",
    ADDVEHICLES: "إضافة مركبات",
    NOVEHICLESFOUND: "لم يتم العثور على مركبات",
    VIEW: "عرض",
    PAY: "ادفع",
    DRIVERS: "السائقين",
    PERMITS: "تصريح",
    NUMBER: "رقم .",
    DELIVERYVEHICLE: "مركبة التسليم",
    VEHICLENUMBER: "رقم المركبة",
    UPLOADNEWDOCUMENTS: "تحميل مستندات جديدة",
    SELECTDRIVERS: "حدد السائقين",
    SELECTPERMITS: "حدد التصاريح",
    UPDATE: "تحديث",
    EDIT: "تعديل",
    DELETE: "حذف",
    SEARCHDRIVERS: "البحث عن سائقين",
    PHONE: "هاتف",
    LICENSEDETAILS: "تفاصيل الرخصة",
    NOTRIPSAVAILABLE: "لا توجد رحلات متاحة",
    USERTYPE: "نوع المستخدم",
    EDITPROFILE: "تعديل الملف",
    YOURNAME: "اسمك الأول واسم العائلة",
    YOUREMAIL: "بريدك الإلكتروني (اختياري)",
    YOURPHONE: "هاتفك",
    SELFDRIVER: "سائق ذاتي",
    NEWPASSWORD: "كلمة المرور الجديدة",
    OLDPASSWORD: "كلمة المرور القديمة",
    ADDDRIVERS: "أضف السائقين",
    DETAILS: "تفاصيل",
    NAMEISREQUIRED: "مطلوب اسم",
    USERNAMEREQUIRED: "اسم المستخدم مطلوب",
    INVALIDPHONENUMBER: "رقم الهاتف غير صحيح",
    USERNAMEDUPLICATEFOUND: "تم العثور على اسم مستخدم مكرر",
    USERNAMEALREADYEXISTS: "اسم المستخدم موجود بالفعل",
    PASSWORDREQUIRED: "كلمة المرور مطلوبة",
    DRIVERREGEX: "يرجى تضمين الأحرف الكبيرة والرقم والأحرف الخاصة.",
    CONFIRMPASSWORDREQUIRED: "تأكيد كلمة المرور مطلوب",
    PASSWORDMUSTMATCH: "كلمة المرور يجب ان تتطابق",
    ENTERIDNUMBER: "ادخل رقم الرخصة",
    VEHICLEASSIGNED: "المركبة المخصصة",
    BACK: "الى الخلف",
    OPTIONALEMAIL: "بريدك الإلكتروني (اختياري)",
    YOURPASSWORD: "كلمة المرور الجديدة",
    CONFIRMPASSWORD: "تأكيد كلمة المرور",
    DRIVERNAME: "اسم السائق",
    DRIVERRATING: "تقييم السائق",
    LOGIBIDSONINSTAGRAM: "لوجيبدز على انسنجرام",
    LOGIBIDSONFACEBOOK: "لوجيبدز على الفيسبوك",
    LOGIBIDSONX: "لوجيبدز على تويتر",
    CONTACTUS: "اتصل بنا",
    CONTACTUSS: "اتصل بنا",
    ACCEPT: "قبول",
    WAITINGFORPAYMENT: "انتظار الدفع",
    CONFIRMATION: "تأكيد",
    OVERALLQUALITY: "ماهو تقييمك الاجمالى للرحلة؟",
    PLEASEADDCOMMENT: "من فضلك ضع تعليقك هنا",
    READY: "جاهز",
    STARTTRIP: "ابدأ الرحلة",
    ENDTRIP: "انهى الرحلة",
    QUIT: "خروج",
    YOUWONBIDFOR: "انت ربحت المزايدة ب",
    PLEASESELECTDRIVER: "من فضلك اختر سائق",
    DELIVERIES: "التوصيل",
    WAITINGFORCONFIRM: "في انتظار تأكيد العميل.",
    MYBID: "مزايدتى",
    LOSTYOURBID: "خسرت مزايدتك",
    SERVICEPROVIDER: "مقدم الخدمة",
    DRIVERR: "سائق",
    TRANSPORTPUBLIC: "النقل العام",
    TRANSPORTGOODS: "نقل بضائع",
    RENTALHEAVY: "تأجير معدات ثقيلة",
    RENTALGOODS: "ايجار نقل البضائع",
    RENTALOTHERS: "تأجير-أخرى",
    WALLET: "محفظة نقود",
    YOURWALLETBALANCE: "رصيدك في المحفظة",
    ADDMORE: "أضف المزيد",
    CATEGORY: "الفئة",
    PLEASESELECTVEHICLE: "الرجاء اختيار المركبة",
    PLEASESELECTBRAND: "الرجاء تحديد العلامة التجارية",
    ENTERVEHICLENAME: "أدخل اسم المركبه",
    NORESULTSFOUND: "لم يتم العثور على نتائج",
    HASSUBCATEGORY: "لديها فئة فرعية؟",
    ENTERSUBCATEGORY: "أدخل الفئة الفرعية",
    UPLOADIMAGE: "تحميل الصور",
    PHOTOREQUIRED: "الصورة مطلوبة",
    PASSWORD: "كلمه السر",
    UPLOADYOURLICENSE: "قم بتحميل رخصتك",
    MORE: "المزيد",
    LOADING: "جار التحميل",
    LOGIBIDSDEPOSITAMOUNT: "ايداع مبلغ للوجيبدز",
    PAYMENTFAILED: "فشل في عملية الدفع",
    PAYMENTSUCCESS: "تم الدفع بنجاح",
    REGESTRATIONFAILED: "فشل في التسجيل",
    GOHOME: "اذهب للصفحة الرئيسية",
    REGESTRATIONCOMPLETED: "أكملت تسجيل",
    PAYMENT: "الدفع",
    SELECTPAYMENTGATEWAY: "حدد بوابة الدفع",
    HESABE: "حسابي",
    PLEASEWAITREDIRECTEDINFIVESECONDS:
      "من فضلك انتظر ستتم إعادة توجيه هذا خلال 5 ثواني",
    PLEASESELECTCATEGORY: "الرجاء تحديد الفئة",
    NOOFVEHICLE: "رقم المركبة",
    NUMBERISREQUIRED: "الرقم مطلوب",
    DUPLICATEEXISTS: "مكررة موجودة",
    DOCUMENTISREQUIRED: "الوثيقة مطلوبة",
    SAVE: "حفظ",
    TYPE: "يكتب",
    ENTEVEHICLENUMBER: "أدخل رقم المركبة",
    HEAVY: "ثقيل",
    OTHERS: "آحرون",
    ENTERVEHICLENUMBER: "أدخل رقم المركبة",
    VEHICLEEXITS: "المركبة موجودة",
    SOMETHINGELSEHERE: "شيء آخر هنا",
    REGIONS: "المناطق",
    TYPEOFCATEGORY: "نوع الفئة",
    SEARCHNEARESTLOCATION: "البحث عن أقرب موقع",
    ENTERCODE: "ادخل الرمز",
    AIRPORT: "مطار",
    PAYMENTSUCCESFULL: "تم الدفع بنجاح",
    REDIRECTIONTOLOGINPAGEAFTERSECONDS:
      "إعادة التوجيه إلى صفحة تسجيل الدخول خلال 5 ثواني",
    OTP: "رمز التحقق",
    toggle_active: "تم التنشيط بنجاح",
    toggle_inactive: "تم الغاء التنشيط بنجاح",
    no_driver: "کوئی ڈرائیور نہیں ملا",
    Profile_Message: "پروفائل کامیابی کے ساتھ مکمل ہو گیا ہے۔",
    loginPage: {
      phoneRequired: "براہ کرم موبائل نمبر درج کریں۔",
      phoneInvalid: "براہ کرم درست موبائل نمبر درج کریں۔",
      passwordRequired: "براہ کرم پاس ورڈ درج کریں۔",
      passwordMinLength: "كلمة المرور لا يمكن أن تكون أقل من 4 أحرف"
    },
    addVehicleMessage: "گاڑی کامیابی کے ساتھ شامل کی گئی۔",
    noti_label: "اشعارات",
    adddriver_success: "تم تعيين السائق بنجاح",
    tripStatus: {
      readyMessage: "السائق جاهز للالتقاط.",
      startMessage: "بدأ السائق الرحلة.",
      endMessage: "أنهى السائق الرحلة.",
    },
    payment_status: {
      success_message: "الدفع الناجح",
      error_message: "عملية الدفع فشلت",
    },
    editProfile: {
      email_invalid: "الرجاء إدخال البريد الإلكتروني الصحيح",
    },
    pendingProfileMessage:
      "مستندات التحقق من هويتك في انتظار المراجعة حاليًا. نحن نقدر سعة صدرك وسنخطرك بمجرد اكتمال العملية.",
    rejectProfileMessage:
      "نأسف لإبلاغك بأنه قد تم رفض مستندات التحقق من هويتك. يرجى مراجعتها وإعادة إرسالها باتباع إرشاداتنا للتحقق بنجاح. اتصل بنا للحصول على أي مساعدة.",
    profileStatusTitle: "حالة المستندات",
    TRIPS: "رحلات",
  },

  en: {
    SELECTVEHICLE: "Select Vehicle",
    RATINGREVIEW: "Rating & Review",
    MYRATING: "My Ratings",
    NONOTIFICATION: "No Notification Found !",
    BRAND: "Type of Brand",
    MODEL: "Vehicle Specialization",
    IMMEDIATE: "Immediate",
    SCHEDULE: "Schedule",
    WIDTH: "Width",
    HEIGHT: "Weight",
    LENGTH: "Length",
    ADDRESSTYPE: "Address Type",
    AREA: "Area",
    APARTMENTNO: "Apartment No.",
    AVENUE: "Avenue",
    BUILDINGNO: "Building No",
    ADDITIONALINFO: "Additional Info",
    addVehicleMessage1: "Please first add vehicles",
    rating: "Rating",
    review: "Review",
    CHANGEPASSWORD: "Change Password",
    noRating: "No Rating",
    oldPasswordMessage: "Please enter old password",
    newPasswordMessage: "Please enter new password",
    newPasswordMinLengthMessage: "New Password can't be less than 4 characters",
    confirmPasswordMessage: "Please enter confirm password",
    newconfirmrequired: "Please enter New Password and Confirm Password",
    oldconfirmrequired: "Please enter Old Password and Confirm Password",
    oldnewPassword: "Please enter Old password and New Password",
    OLDPASSWORDNEWPASSWORD: "Old password & new password can't be same",
    ENTERLICENSENUMBER: "Enter License Number",
    OTHERS: "Others",
    HEAVY: "Heavy",
    PUBLIC: "Public",
    GOODS: "Goods",
    ACTION: "Action",
    ANOTHERACTION: "Another  action",
    SOMETHINGELESEHERE: "Something else here",
    INTERNATIONALPERMIT: "International Permit",
    SELECTPERMITREGION: "Select Permit Region",
    VEHICLE: "Vehicle",
    SUBCATEGORYISREQUIRED: "Subcategory is required",
    PLEASESELECTSUBCATEGORY: "Please Select SubCategory",
    TYPE: "Type",
    SAVE: "Save",
    DOCUMENTISREQUIRED: "Document is required",
    DUPLICATEEXISTS: "Duplicate Exists",
    NOOFVEHICLE: "No of Vehicle",
    PLEASEWAITREDIRECTEDINFIVESECONDS:
      "Please wait this will redirected in 5 seconds",
    HESABE: "Hesabe",
    SELECTPAYMENTGATEWAY: "Select Payment Gateway",
    PAYMENT: "Payment",
    PAYMENTSUCCESS: "Payment Succesfull",
    REGESTRATIONCOMPLETED: "Registration Complete",
    OTP: "OTP",
    REDIRECTIONTOLOGINPAGEAFTERSECONDS:
      " Redirecting to login page in 5 seconds",
    PAYMENTSUCCESFULL: "Payment Succesfull",
    GOHOME: "Go Home",
    REGESTRATIONFAILED: "Registration Failed",
    AIRPORT: "Airport",
    ENTERCODE: "Enter Code",
    TYPEOFCATEGORY: "Type of Category",
    SEARCHNEARESTLOCATION: "Search Nearest Location",
    REGIONS: "Regions",
    SOMETHINGELSEHERE: "SomethIng else here",
    VEHICLEEXITS: "Vehicle Exists",
    ENTERVEHICLENUMBER: "Enter Vehicle Number",
    PLEASESELECTCATEGORY: "Please Select Category",
    RENTAL: "Rental",
    PAYMENTFAILED: "Payment Failed",
    LOGIBIDSDEPOSITAMOUNT: "Logibids Deposit Amount",
    LOADING: "Loading",
    MORE: "More",
    PASSWORD: "Password",
    PHOTOREQUIRED: "Photo is required",
    UPLOADIMAGE: "Upload Image",
    ENTERSUBCATEGORY: "Enter Subcategory",
    HASSUBCATEGORY: "Has Sub Category ?",
    NORESULTSFOUND: "No Results Found",
    ENTERVEHICLENAME: "Enter Vehicle Name",
    PLEASESELECTBRAND: "Please Select Brand",
    PLEASESELECTVEHICLE: "Please Select Vehicle",
    CATEGORY: "Category",
    ADDMORE: "Add More",
    YOURWALLETBALANCE: "Your Wallet Balance",
    WALLET: "Wallet",
    RENTALGOODS: "RENTAL-GOODS",
    RENTALOTHERS: "RENTAL-OTHERS",
    RENTALHEAVY: "RENTAL-HEAVY",
    TRANSPORTGOODS: "TRANSPORT-GOODS",
    TRANSPORTPUBLIC: "TRANSPORT-PUBLIC",
    SERVICEPROVIDER: "SERVICE PROVIDER",
    DRIVERR: "DRIVER",
    LOSTYOURBID: "Lost Your Bid",
    MYBID: "My Bid",
    WAITINGFORCONFIRM: "Waiting for customer to confirm.",
    DELIVERIES: "Deliveries",
    PLEASESELECTDRIVER: "Please Select Driver",
    YOUWONBIDFOR: "YOU WON BID FOR",
    QUIT: "Quit",
    ENDTRIP: "End Trip",
    STARTTRIP: "Start Trip",
    READY: "Ready",
    PLEASEADDCOMMENT: "Please Add Your Comment",
    OVERALLQUALITY: "How would you rate the overall quality of this trip ?",
    CONFIRMATION: "Confirmation",
    WAITINGFORPAYMENT: "Waiting For Payment",
    CONTACTUSS: "Contact",
    WITHDRAW: "Withdraw",
    CONTRACT: "Contract",
    CONTACTUS: "Contact US",
    LOGIBIDSONFACEBOOK: "Logibids on Facebook",
    LOGIBIDSONINSTAGRAM: "Logibids on Instagram",
    LOGIBIDSONX: "Logibids on Twitter",
    TERMSANDCONDITIONS: "Terms and Condition",
    DRIVERNAME: "Driver Name",
    CONFIRMPASSWORD: "Confirm Password",
    YOURPASSWORD: "New Password",
    OPTIONALEMAIL: "Your Email(optional)",
    BACK: "back",
    VEHICLEASSIGNED: "Vehicle Assigned",
    ENTERIDNUMBER: "Enter ID Number",
    PASSWORDMUSTMATCH: "Password must match",
    CONFIRMPASSWORDREQUIRED: "Confirm Password is required",
    DRIVERREGEX: "Please include uppercase, number and special characters.",
    PASSWORDREQUIRED: "Password is required",
    USERNAMEALREADYEXISTS: "Phone No Already Exists",
    INVALIDPHONENUMBER: "Invalid Phone number",
    USERNAMEREQUIRED: "Please enter Phone no",
    NAMEISREQUIRED: "Name is required",
    DETAILS: "Details",
    ADDDRIVERS: "Add Drivers",
    OLDPASSWORD: "Old Password",
    NEWPASSWORD: "New Password",
    SELFDRIVER: "Self Driver",
    YOURPHONE: "Your Phone",
    YOUREMAIL: "Your Email (Optional)",
    YOURNAME: "Your First Name & Last Name",
    EDITPROFILE: "Edit Profile",
    USERTYPE: "User Type",
    NOTRIPSAVAILABLE: "No Trips Available",
    DRIVER: "DRIVER",
    CANCEL: "Cancel",
    LICENSEDETAILS: "License Details",
    PHONE: "Phone",
    SEARCHDRIVERS: "Search Drivers",
    DELETE: "Delete",
    EDIT: "Edit",
    UPDATE: "Update",
    SELECTDRIVERS: "Select Drivers",
    SELECTPERMITS: "Select Permits",
    UPLOADNEWDOCUMENTS: "Upload New Documents",
    VEHICLENUMBER: "Vehicle Number",
    DELIVERYVEHICLE: "Delivery Vehicle",
    NUMBER: "No.",
    PERMITS: "Permits",
    DRIVERS: "Drivers",
    PAY: "Pay",
    VIEW: "View",
    NOVEHICLESFOUND: "No Vehicles Found",
    ADDVEHICLES: "Add Vehicles",
    SEARCHVEHICLES: "Search Vehicles",
    DELETEACCOUNT: "Delete Account",
    LOGOUT: "Logout",
    MYPROFILE: "My Profile",
    MYTRIPS: "My Trips",
    MYDRIVERS: "My Drivers",
    MYVEHICLES: "My Vehicles",
    MYDOCUMENTS: "My Documents",
    ENTERYOURPASSWORD: "Enter Your Password",
    LOGIN: "Login",
    CONFIRMPICKUP: "Confirm Pickup",
    SETYOURLOCATION: "Set your Location",
    LANGUAGE: "Language",
    GETSTARTED: "Get Started",
    DONTRECIEVE: "Don't receive OTP ?",
    ENTERPHONENUMBER: "Enter your phone number",
    ENTERNEWPASSWORD: "Enter New Password",
    ENTERCODENEWPASSWORD: "Enter Your Code And New Password",
    FORGOTPASSWORD: "Forgot Password",
    COPYRIGHT: "Copyright © 2010-2020 (Transport Service)",
    NEXT: "Next",
    ALLBRAND: "All Brands",
    YOURACCOUNT: "Your Account",
    BIDNOW: "Bid Now",
    TRANSPORT: "Transport",
    VEHICLEREQUIRED: "Vehicle is required",
    BRANDREQUIRED: "Brand is required",
    DELIVERY: "DELIVERY",
    AGREECONTINUE: "Agree & Continue",
    ADD: "Add",
    UPLOADYOURLICENSE: "Upload Your License",
    UPLOADYOURID: "ID Number",
    NOOFTRIPS: "No of Trips",
    DOCUMENTS: "Documents",
    UPLOADCOMPANYLICENSE: "Company License/Driving License",
    ACCEPTTERMSANDCONDITION: "Please accept terms and conditions",
    SUBMIT: "Submit",
    FUEL: "Fuel",
    REJECTTRIP: "Reject Trip",
    PICKUP: "Pickup",
    DELIVER: "Deliver",
    CALL: "Call",
    ACCEPTTRIP: "Accept Trip",
    SHOWMAP: "Show Map",
    SERVICE: "Maintenance",
    DAYS: "Days",
    SIGNUP: "Signup",
    HOURS: "Hours",
    MINUTES: "Minutes",
    HOURSPERDAY: "Hours / Day",
    NOOFDAYS: "No of Days",
    totalKM: "Km/Day",
    KMSPERDAY: "Kms / Days",
    CREATEBID: "Create Bid",
    ENQUIRYENDS: "ENQUIRY ENDS",
    PRIVACYPOLICY: "Privacy Policy",
    NAME: "Name",
    TO: "To",
    CONTACT: "Contact",
    EMAIL: "Email",
    CUSTOMERRATING: "Customer Rating",
    AVERAGERATING: "Average Rating",
    BIDFOR: "BID FOR",
    FROM: "FROM",
    YOU: "YOU",
    GOTOTRIP: "Go to Trip",
    USERDETAILS: "User Details",
    DRIVERDETAILS: "Driver Details",
    WEIGHTOFCOMMODITY: "Weight of Commodity",
    DELIVERYCHARGE: "Delivery Charge",
    TOTALDISTANCE: "Total Distance",
    PICKUPSCHEDULED: "Pick up Schedule",
    TRACKDRIVER: "Track Driver",
    TYPEOFCOMMODITY: "Type of Commodity",
    TYPEOFDELIVERY: "Type of Delivery",
    REMAININGTIMEFORPICKUP: "REMAINING TIME FOR PICKUP",
    ADDBIDAMOUNT: "Add Bid Amount",
    ENQUIRYDETAILPAGE: "Enquiry Detail Page",
    TYPEOFVEHICLE: "Type of Vehicle",
    HOME: "HOME",
    ADDDRIVER: "Add Driver",
    ACCEPT: "Accept",
    ADDVEHICLE: "Add Vehicle",
    COMPLETEYOURPROFILE: "Complete Your Profile",
    NOACTIVEBID: "There Is No Active Bid For You",
    YOURLASTBID: "Your last bid",
    SEARCHENQUIRY: "Search Enquiry",
    NORECORDSFOUND: "No Enquiry Found",
    ENQUIRIES: "Enquiries",
    AUCTIONS: "Auctions",
    RUNNING: "RUNNING",
    PENDING: "PENDING",
    ENDED: "ENDED",
    CANCELED: "CANCELED",
    BIDDING_STARTED: "BIDDING STARTED",
    HALF_TIME: "HALF TIME OVER",
    ENDING_SOON: "ENDING SOON",
    BIDS: "BIDS",
    WON: "WON",
    active: "Active",
    inactive: "Inactive",
    login_error_1: "Please provide valid details !",
    Required: "*Required",
    Invalid: "*Invalid",
    Invalid_PhnNumber: "* Valid Phone Number Required",
    password_character:
      " * The password must be at least 8 characters, 1 lowercase ,1 uppercase ,1 number, 1 special characters",
    password_must: "  * Passwords must match",
    forgot_pwd_msg: "Please Enter Valid Phone Number !",
    upload: "Upload Document",
    max: "Max driver limit is 5",
    added_user: "Added User As Driver",
    unadded_user: "Unselect User As Driver",
    added: "Profile is succesfully completed",
    something: "Something went wrong",
    driver_message: "Are you sure that you want to delete this driver?",
    acpt_lbl: "yes",
    rjct_lbl: "no",
    account_message: "Are you sure that you want to delete your account ?",
    vehicle_message: "Are you sure that you want to delete this vehicle?",
    Your: "Your Last bid",
    driver_unavailable: "Driver is Unavailable !",
    Change: "Change Driver",
    Add: "Add",
    signup_succ: "Signup Successfull",
    confirm: " Please wait 5 seconds for verification!",
    otp_sent: "OTP sent successfully",
    something_wrong: "Something went wrong",
    enq_acpt: "Enquiry Accepted !",
    bid_amt: "Bid Amount Required!",
    enter_amt: "Please Enter Amount less than current bid",
    you_cant: "You cannot bid consecutively",
    adding_bid: "Adding Bid",
    succ_add: "Successfully Added!",
    plz_slct_drvr: "Please Select Driver !",
    success: "Success",
    enq_rjct: "Enquiry Rejected !",
    DRIVERRATING: "Driver Rating",
    start_trip: "Are you sure that you want to start the trip ?",
    reject_trip: "Are you sure that you want to reject the trip ?",
    replace_driver_self:
      "Are you sure that you want to assign the trip to another driver?",
    replace_driver_request:
      "Are you sure that you want to request for driver replacement ?",
    plz_upload: "Please upload all documents",
    plz_accpt: "Please Accept Terms and Conditions",
    rmd_drvr: "Removed Driver",
    dlt_vehicle: "Are you sure that you want to delete this vehicle ?",
    rmd_vhcl: "Removed Vehicle",
    prf_updt: "Profile Updated",
    prf_pwd_updt: "Password Updated",
    Driver: "Driver",
    Permits: "Permits",
    Agree: "Agree",
    ok: "Okay!",
    rating_submit: "Rating Submitted Successfully.",
    enquiry_del_msg: "This enquiry is no longer accessible.",
    bids: "NO BIDS",
    vehicles: "Vehicles",
    passwordstr10: "",
    passwordstr1: "1) Your password must contain at least 8 characters.",
    passwordstr2:
      "2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.)",
    passwordstr3:
      "3) Your password must contain at least one lowercase letter.",
    passwordstr4:
      "4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)",
    passwordstr5:
      "5) Your password must contain at least one special character -for example: $, #, @, !,%,^,&,*,(,)",
    NUMBERISREQUIRED: "Number is required",
    VEHICLEEXISTS: "Vehicle Exists",
    ENTEVEHICLENUMBER: "Enter Vehicle Number",
    toggle_active: "Active Successfully",
    toggle_inactive: "Inactive Successfully",
    no_driver: "No driver Found",
    Profile_Message: "Profile is successfully completed",
    loginPage: {
      phoneRequired: "Please enter mobile number",
      phoneInvalid: "Please enter valid mobile number",
      passwordRequired: "Please enter password",
      passwordMinLength: "Password can't be less than 4 characters"
    },
    addVehicleMessage: "Vehicle added successfully",
    noti_label: "Notification",
    adddriver_success: "Driver assign Successfully",
    tripStatus: {
      readyMessage: "Driver is ready for pickup.",
      startMessage: "Driver has started trip.",
      endMessage: "Driver has ended trip.",
    },
    payment_status: {
      success_message: "Payment Success",
      error_message: "Payment Failed",
    },
    editProfile: {
      email_invalid: "Please enter valid email Id",
    },
    licenceIdNumberRequired: "Enter your licence number",
    companylicenceNumberRequired: "Enter company licence number",
    personalLicencePhotoRequired: "Upload your licence photo",
    companyLicencePhotoRequired: "Upload your licence photo",

    pendingProfileMessage:
      "Your ID verification documents are currently pending review. We appreciate your patience and will notify you once the process is complete.",
    rejectProfileMessage:
      "We regret to inform you that your ID verification documents have been rejected. Please review and resubmit them following our guidelines for successful verification. Contact us for any assistance.",
    profileStatusTitle: "Document Status",
    TRIPS: "Trips",
  },

  ml: {
    SELECTVEHICLE: "വാഹനം തിരഞ്ഞെടുക്കുക",
    RATINGREVIEW: "റേറ്റിംഗും അവലോകനവും",
    MYRATING: "എൻ്റെ റേറ്റിംഗുകൾ",
    NONOTIFICATION: "അറിയിപ്പൊന്നും കണ്ടെത്തിയില്ല !",
    BRAND: "ബ്രാൻഡ് തരം",
    MODEL: "മോഡൽ",
    IMMEDIATE: "ഉടനെ",
    SCHEDULE: "ഷെഡ്യൂൾ ",
    WIDTH: "വീതി",
    HEIGHT: "ഭാരം",
    LENGTH: "നീളം",
    ADDRESSTYPE: "വിലാസ ഇനം",
    AREA: "ഏരിയ",
    APARTMENTNO: "അപ്പാർട്ട്മെൻ്റ് നമ്പർ",
    AVENUE: "അവന്യൂ",
    BUILDINGNO: "കെട്ടിട നമ്പർ",
    ADDITIONALINFO: "അധിക വിവരങ്ങൾ",
    addVehicleMessage1: "ദയവായി ആദ്യം വാഹനങ്ങൾ ചേർക്കുക",
    rating: "റേറ്റിംഗ്",
    review: "അവലോകനം",
    CHANGEPASSWORD: "പാസ്സ്‌വേർഡ് മാറ്റുക",
    noRating: "റേറ്റിംഗ് ഇല്ല",
    oldPasswordMessage: "ദയവായി പഴയ പാസ്സ്‌വേർഡ് നൽകുക",
    newPasswordMessage: "ദയവായി പുതിയ പാസ്സ്‌വേർഡ് നൽകുക",
    newPasswordMinLengthMessage: "പുതിയ പാസ്സ്‌വേർഡ് 4 പ്രതീകങ്ങളിൽ കുറവായിരിക്കരുത്",
    confirmPasswordMessage: "ദയവായി സ്ഥിരീകരണ പാസ്സ്‌വേർഡ് നൽകുക",
    newconfirmrequired:
      "ദയവായി പുതിയ പാസ്സ്‌വേർഡ് നൽകി പാസ്സ്‌വേർഡ്സ്ഥിരീകരിക്കുക",
    oldconfirmrequired:
      "ദയവായി പഴയ പാസ്സ്‌വേർഡ് നൽകി പാസ്സ്‌വേർഡ്സ്ഥിരീകരിക്കുക",
    oldnewPassword: "ദയവായി പഴയ പാസ്‌വേഡും പുതിയ പാസ്‌വേഡും നൽകുക",
    OLDPASSWORDNEWPASSWORD: "പഴയ പാസ്‌വേഡും പുതിയ പാസ്‌വേഡും ഒന്നാകരുത്",
    TYPEOFDELIVERY: "ഡെലിവറി തരം",
    ENTERLICENSENUMBER: "ലൈസൻസ് നമ്പർ നൽകുക",
    OTHERS: "മറ്റുള്ളവ",
    HEAVY: "ഭാരമേറിയ",
    ENTEVEHICLENUMBER: "വാഹന നമ്പർ നൽകുക",
    PUBLIC: "പൊതു ഗതാഗതം",
    GOODS: "ചരക്ക്  ഗതാഗതം",
    ACTION: "ആക്ഷൻ",
    ANOTHERACTION: "മറ്റൊരു പ്രവർത്തനം",
    SOMETHINGELESEHERE: "ഇവിടെ മറ്റെന്തെങ്കിലും",
    INTERNATIONALPERMIT: "അന്താരാഷ്ട്ര അനുമതി",
    SELECTPERMITREGION: "പെർമിറ്റ് മേഖല തിരഞ്ഞെടുക്കുക",
    VEHICLEEXISTS: "വാഹനം നിലവിലുണ്ട്",
    VEHICLE: "വാഹനം",
    SUBCATEGORYISREQUIRED: "ഉപവിഭാഗം ആവശ്യമാണ്",
    PLEASESELECTSUBCATEGORY: "ദയവായി ഉപവിഭാഗം തിരഞ്ഞെടുക്കുക",
    TYPE: "തരം",
    SAVE: "സേവ് ചെയുക ",
    DOCUMENTISREQUIRED: "രേഖ ആവശ്യമാണ്",
    DUPLICATEEXISTS: "ഡ്യൂപ്ലിക്കേറ്റ് നിലവിലുണ്ട്",
    NUMBERISREQUIRED: "നമ്പർ ആവശ്യമാണ്",
    NOOFVEHICLE: "വാഹനത്തിന്റെ നമ്പർ",
    PLEASESELECTCATEGORY: "ദയവായി വിഭാഗം തിരഞ്ഞെടുക്കുക",
    PLEASEWAITREDIRECTEDINFIVESECONDS:
      "ദയവായി കാത്തിരിക്കൂ, ഇത് 5 സെക്കൻഡിനുള്ളിൽ റീഡയറക്‌ടുചെയ്യും",
    HESABE: "Hesabe",
    SELECTPAYMENTGATEWAY: "പേയ്‌മെന്റ് ഗേറ്റ്‌വേ തിരഞ്ഞെടുക്കുക",
    PAYMENT: "പേയ്മെന്റ്  ",
    PAYMENTSUCCESS: "പേയ്‌മെന്റ് വിജയിച്ചു",
    REGESTRATIONCOMPLETED: "രജിസ്ട്രേഷൻ പൂർത്തിയായി",
    GOHOME: "ഗോ ഹോം ",
    REGESTRATIONFAILED: "രജിസ്ട്രേഷൻ പരാജയപ്പെട്ടു",
    OTP: "ഒ  ടി പി ",
    REDIRECTIONTOLOGINPAGEAFTERSECONDS:
      "5 സെക്കൻഡിനുള്ളിൽ ലോഗിൻ പേജിലേക്ക് റീഡയറക്‌ട് ചെയ്യുന്നു",
    PAYMENTSUCCESFULL: "പേയ്‌മെന്റ് വിജയിച്ചു",
    AIRPORT: "വിമാനത്താവളം",
    ENTERCODE: "കോഡ് നൽകുക",
    TYPEOFCATEGORY: "വിഭാഗത്തിൻ്റെ തരം",
    SEARCHNEARESTLOCATION: "അടുത്തുള്ള ലൊക്കേഷൻ തിരയുക",
    REGIONS: "പ്രദേശങ്ങൾ",
    DRIVERRATING: "ഡ്രൈവർ റേറ്റിംഗ്",
    SOMETHINGELSEHERE: "ഇവിടെ മറ്റെന്തെങ്കിലും",
    VEHICLEEXITS: "വാഹനം നിലവിലുണ്ട്",
    ENTERVEHICLENUMBER: "വാഹന നമ്പർ നൽകുക",
    PAYMENTFAILED: "പേയ്മെന്റ്  പരാജയപ്പെട്ടു",
    LOGIBIDSDEPOSITAMOUNT: "ലോജിബിഡ്സ് നിക്ഷേപ തുക",
    LOADING: "ലോഡിംഗ്",
    MORE: "കൂടുതൽ",
    PASSWORD: " പാസ്സ്‌വേർഡ്",
    PHOTOREQUIRED: "ഫോട്ടോ ആവശ്യമാണ് ",
    UPLOADIMAGE: "ചിത്രം അപ്‌ലോഡ്  ചെയ്യുക",
    ENTERSUBCATEGORY: "ഉപവിഭാഗം നൽകുക",
    HASSUBCATEGORY: " ഉപവിഭാഗം ഉണ്ടോ?",
    NORESULTSFOUND: "ഒരു ഫലവും കണ്ടെത്താനായില്ല",
    ENTERVEHICLENAME: "വാഹനത്തിൻ്റെ പേര് നൽകുക",
    PLEASESELECTBRAND: "ദയവായി ബ്രാൻഡ് തിരഞ്ഞെടുക്കുക",
    PLEASESELECTVEHICLE: "ദയവായി വാഹനം തിരഞ്ഞെടുക്കുക",
    CATEGORY: "വിഭാഗം",
    ADDMORE: "കൂടുതൽ ചേർക്കുക",
    YOURWALLETBALANCE: "നിങ്ങളുടെ വാലറ്റിലെ തുക ",
    WALLET: "വാലറ്റ്",
    RENTALGOODS: "വാടക സാധനങ്ങൾ",
    RENTALOTHERS: "വാടകക്ക് - മറ്റുള്ളവ",
    RENTALHEAVY: "വലിയ വാടക സാധനങ്ങൾ ",
    TRANSPORTGOODS: "ചരക്ക് ഗതാഗതം",
    TRANSPORTPUBLIC: "പൊതു ഗതാഗതം ",
    SERVICEPROVIDER: "സേവനദാതാവ്",
    DRIVERR: "ഡ്രൈവർ",
    LOSTYOURBID: "നിങ്ങളുടെ ലേലം  നഷ്ടപ്പെട്ടു",
    MYBID: "എൻ്റെ  ലേലം",
    WAITINGFORCONFIRM: "ഉപഭോക്താവ് സ്ഥിരീകരിക്കുന്നതിനായി കാത്തിരിക്കുന്നു ",
    DELIVERIES: "ഡെലിവറികൾ",
    PLEASESELECTDRIVER: "ദയവായി ഡ്രൈവറെ  തിരഞ്ഞെടുക്കുക",
    YOUWONBIDFOR: "നിങ്ങൾ ലേലം ചെയ്തു",
    QUIT: "ഉപേക്ഷിക്കുക",
    ENDTRIP: "യാത്ര അവസാനിപ്പിക്കുക",
    STARTTRIP: "യാത്ര ആരംഭിക്കുക",
    READY: "തയ്യാറാകുക",
    PLEASEADDCOMMENT: "ദയവായി നിങ്ങളുടെ അഭിപ്രായം ചേർക്കുക ",
    OVERALLQUALITY:
      "ഈ യാത്രയുടെ മൊത്തത്തിലുള്ള ഗുണനിലവാരം നിങ്ങൾ എങ്ങനെ വിലയിരുത്തും?",
    CONFIRMATION: "സ്ഥിരീകരണം",
    WAITINGFORPAYMENT: "പേയ്‌മെൻ്റിനായി കാത്തിരിക്കുന്നു",
    CONTACTUSS: "ബന്ധപ്പെടുക",
    WITHDRAW: "പിൻവലിക്കുക ",
    CONTRACT: "കരാർ",
    CONTACTUS: "ഞങ്ങളെ സമീപിക്കുക",
    LOGIBIDSONFACEBOOK: "ഫേസ്ബുക്കിലെ ലോജിബിഡ്‌സ് ",
    LOGIBIDSONINSTAGRAM: "ഇൻസ്റ്റാഗ്രാമിലെ  ലോജിബിഡ്‌സ് ",
    LOGIBIDSONX: "ട്വിറ്ററിലെ ലോജിബിഡുകൾ",
    TERMSANDCONDITIONS: "നിബന്ധനകളും വ്യവസ്ഥകളും",
    DRIVERNAME: "ഡ്രൈവറുടെ പേര് ",
    CONFIRMPASSWORD: "പാസ്സ്‌വേർഡ്  സ്ഥിരീകരിക്കുക ",
    YOURPASSWORD: "പുതിയ പാസ്സ്‌വേർഡ്",
    OPTIONALEMAIL: "നിങ്ങളുടെ ഇമെയിൽ (ഓപ്ഷണൽ)",
    BACK: "പുറകിലോട്ട്  പോകുക ",
    VEHICLEASSIGNED: "വാഹനം അനുവദിച്ചു",
    ENTERIDNUMBER: "ഐഡി നമ്പർ നൽകുക",
    PASSWORDMUSTMATCH: "പാസ്സ്‌വേർഡ്  തുല്യമായിരിക്കണം ",
    CONFIRMPASSWORDREQUIRED: " പാസ്സ്‌വേർഡിൽ സ്ഥിരീകരിക്കേണ്ടത് ആവശ്യമാണ്",
    DRIVERREGEX: "വലിയക്ഷരവും അക്കവും പ്രത്യേക പ്രതീകങ്ങളും ഉൾപ്പെടുത്തുക.",
    PASSWORDREQUIRED: "പാസ്സ്‌വേർഡ്  ആവശ്യമാണ് ",
    USERNAMEALREADYEXISTS: "ഉപയോക്തൃനാമം ഇതിനകം നിലവിലുണ്ട്",
    INVALIDPHONENUMBER: "ഫോൺ നമ്പർ അസാധുവാണ്",
    USERNAMEREQUIRED: "ഉപയോക്തൃനാമം ആവശ്യമാണ്",
    NAMEISREQUIRED: "പേര് ആവശ്യമാണ്",
    DETAILS: "വിവരങ്ങൾ ",
    ADDDRIVERS: "ഡ്രൈവർമാരെ ചേർക്കുക ",
    OLDPASSWORD: "പഴയ പാസ്സ്‌വേർഡ്",
    NEWPASSWORD: "പുതിയ പാസ്സ്‌വേർഡ്",
    SELFDRIVER: "സ്വയം ഡ്രൈവർ",
    YOURPHONE: "നിങ്ങളുടെ ഫോൺ ",
    YOUREMAIL: "നിങ്ങളുടെ  ഇമെയിൽ",
    YOURNAME: "നിങ്ങളുടെ ആദ്യ പേരും അവസാന പേരും",
    EDITPROFILE: "പ്രൊഫൈൽ എഡിറ്റ് ചെയ്യുക",
    USERTYPE: "ഉപയോക്തൃ തരം",
    NOTRIPSAVAILABLE: "യാത്രകളൊന്നും ലഭ്യമല്ല",
    DRIVER: "ഡ്രൈവർ",
    CANCEL: "റദ്ദാക്കുക",
    LICENSEDETAILS: "ലൈസൻസ് വിവരങ്ങൾ ",
    PHONE: "ഫോൺ ",
    SEARCHDRIVERS: "ഡ്രൈവർമാരെതിരയുക ",
    DELETE: "ഇല്ലാതാക്കുക",
    EDIT: "എഡിറ്റ് ചെയ്യുക",
    UPDATE: "അപ്ഡേറ്റ് ചെയ്യുക",
    SELECTDRIVERS: "ഡ്രൈവർമാരെ  തിരഞ്ഞെടുക്കുക",
    SELECTPERMITS: "അനുമതികൾ തിരഞ്ഞെടുക്കുക",
    UPLOADNEWDOCUMENTS: "പുതിയ പ്രമാണം അപ്‌ലോഡ് ചെയ്യുക",
    VEHICLENUMBER: "വാഹന നമ്പർ",
    DELIVERYVEHICLE: "ഡെലിവറി വാഹനം ",
    NUMBER: "നമ്പർ",
    PERMITS: "അനുമതികൾ",
    DRIVERS: "ഡ്രൈവർമാർ ",
    PAY: "പണം നൽകുക",
    VIEW: "കാണുക",
    NOVEHICLESFOUND: "ഒരു ഫലവും കണ്ടെത്താനായില്ല",
    ADDVEHICLES: "വാഹനങ്ങൾ ചേർക്കുക",
    SEARCHVEHICLES: "വാഹനങ്ങൾ തിരയുക",
    DELETEACCOUNT: "അക്കൗണ്ട് ഇല്ലാതാക്കുക",
    LOGOUT: "പുറത്തുകടക്കുക",
    MYPROFILE: "എൻ്റെ പ്രൊഫൈൽ",
    MYTRIPS: "എൻ്റെ യാത്രകൾ",
    MYDRIVERS: "എൻ്റെ ഡ്രൈവർമാർ",
    MYVEHICLES: "എൻ്റെ വാഹനങ്ങൾ",
    MYDOCUMENTS: "എന്റെ രേഖകൾ ",
    ENTERYOURPASSWORD: "നിങ്ങളുടെ പാസ്സ്‌വേർഡ് നൽകുക",
    LOGIN: "ലോഗിൻ",
    CONFIRMPICKUP: "പിക്കപ്പ് സ്ഥിരീകരിക്കുക",
    SETYOURLOCATION: "നിങ്ങളുടെ ലൊക്കേഷൻ സജ്ജമാക്കുക",
    LANGUAGE: "ഭാഷ ",
    GETSTARTED: "തുടങ്ങാം",
    DONTRECIEVE: "'ഒ  ടി പി ലഭിച്ചില്ല ?",
    ENTERPHONENUMBER: " ഫോൺ നമ്പർ നൽകുക ",
    ENTERNEWPASSWORD: " പുതിയ പാസ്സ്‌വേർഡ് നൽകുക",
    ENTERCODENEWPASSWORD: "നിങ്ങളുടെ കോഡും പുതിയ പാസ്സ്‌വേർഡും നൽകുക ",
    FORGOTPASSWORD: "പാസ്സ്‌വേർഡ്‌ മറന്നോ?",
    COPYRIGHT: "പകർപ്പവകാശം © 2010-2020 ഗതാഗത സേവനം",
    NEXT: "അടുത്തത് ",
    ALLBRAND: "എല്ലാ ബ്രാൻഡുകളും",
    YOURACCOUNT: "നിങ്ങളുടെ അക്കൗണ്ട്",
    BIDNOW: "ഇപ്പോൾ ലേലം വിളിക്കുക",
    TRANSPORT: "ഗതാഗതം",
    VEHICLEREQUIRED: "വാഹനം ആവശ്യമാണ് ",
    BRANDREQUIRED: "ബ്രാൻഡ് ആവശ്യമാണ്",
    RENTAL: "വാടകയ്ക്ക്",
    DELIVERY: "ഡെലിവറി",
    AGREECONTINUE: "അംഗീകരിക്കുക & തുടരുക",
    ADD: "ചേർക്കുക",
    UPLOADYOURLICENSE: "നിങ്ങളുടെ ലൈസൻസ് അപ്‌ലോഡ് ചെയ്യുക",
    // UPLOAD YOUR LICENSE:'നിങ്ങളുടെ ലൈസൻസ് അപ്‌ലോഡ് ചെയ്യുക',
    UPLOADYOURID: "ഐ ഡി നമ്പർ",
    NOOFTRIPS: "യാത്രകളുടെ എണ്ണം ",
    DOCUMENTS: "എന്റെ രേഖകൾ ",
    UPLOADCOMPANYLICENSE: "കമ്പനി ലൈസൻസ്/ഡ്രൈവിംഗ് ലൈസൻസ്",
    ACCEPTTERMSANDCONDITION: "ദയവായി നിബന്ധനകളും വ്യവസ്ഥകളും അംഗീകരിക്കുക",
    SUBMIT: "സമർപ്പിക്കുക",
    FUEL: "ഇന്ധനം",
    REJECTTRIP: "യാത്ര നിരസിക്കുക ",
    PICKUP: "പിക്കപ്പ്",
    DELIVER: "എത്തിക്കുക",
    CALL: "വിളിക്കുക ",
    ACCEPTTRIP: "യാത്ര അംഗീകരിക്കുക",
    SHOWMAP: "മാപ്പ് കാണുക",
    SERVICE: "സർവീസ്",
    DAYS: "ദിവസങ്ങൾ ",
    SIGNUP: "സൈൻ അപ്പ് ചെയ്യുക",
    HOURS: "മണിക്കൂറുകൾ ",
    MINUTES: "മിനിറ്റുകൾ ",
    HOURSPERDAY: "മണിക്കൂർ / ദിവസം",
    NOOFDAYS: "ദിവസങ്ങളുടെ എണ്ണം ",
    totalKM: "കിമീ/ദിവസം",
    KMSPERDAY: "കിലോമീറ്റർ / ദിവസം",
    CREATEBID: "ലേലം നിര്‍മ്മിക്കുക",
    ENQUIRYENDS: "അന്വേഷണം അവസാനിക്കുന്നു",
    PRIVACYPOLICY: "സ്വകാര്യതാ നയം",
    NAME: "പേര് ",
    TO: "ലക്ഷ്യ സ്ഥാനം",
    CONTACT: "ബന്ധപ്പെടുക",
    EMAIL: "ഇമെയിൽ",
    CUSTOMERRATING: "ഉപഭോക്തൃ റേറ്റിംഗ്",
    AVERAGERATING: "ശരാശരി റേറ്റിംഗ്",
    BIDFOR: "ലേലം വിളിക്കുക",
    FROM: "തുടക്ക സ്ഥാനം",
    YOU: "നിങ്ങൾ ",
    GOTOTRIP: "യാത്ര പോകുക",
    USERDETAILS: "ഉപയോക്തൃ വിശദാംശങ്ങൾ",
    DRIVERDETAILS: "ഡ്രൈവറുടെ  വിശദാംശങ്ങൾ",
    WEIGHTOFCOMMODITY: "ചരക്കിന്റെ ഭാരം",
    DELIVERYCHARGE: "ഡെലിവറി ചാർജ്",
    TOTALDISTANCE: "മൊത്തം ദൂരം",
    PICKUPSCHEDULED: "പിക്കപ്പ് സമയം",
    TRACKDRIVER: "ട്രാക്ക് ഡ്രൈവർ",
    TYPEOFCOMMODITY: "ചരക്കിന്റെ തരം",
    REMAININGTIMEFORPICKUP: "പിക്കപ്പിന് ശേഷിക്കുന്ന സമയം",
    ADDBIDAMOUNT: "ബിഡ് തുക ചേർക്കുക",
    ENQUIRYDETAILPAGE: "അന്വേഷണ വിശദാംശങ്ങളുടെ പേജ് ",
    TYPEOFVEHICLE: "വാഹനങ്ങളുടെ തരം",
    HOME: "ഹോം ",
    ADDDRIVER: "ഡ്രൈവറെ  ചേർക്കുക",
    ACCEPT: "സ്വീകരിക്കുക",
    ADDVEHICLE: "വാഹനം  ചേർക്കുക",
    COMPLETEYOURPROFILE: "നിങ്ങളുടെ പ്രൊഫൈൽ പൂർത്തിയാക്കുക",
    NOACTIVEBID: "നിങ്ങൾക്ക്  ഒരു ലേലവും ലഭ്യമല്ല ",
    YOURLASTBID: "നിങ്ങളുടെ അവസാന ബിഡ്:",
    SEARCHENQUIRY: "അന്വേഷണം തിരയുക ",
    NORECORDSFOUND: "അന്വേഷണമൊന്നും കണ്ടെത്തിയില്ല",
    ENQUIRIES: "അന്വേഷണങ്ങൾ",
    AUCTIONS: "ലേലങ്ങൾ",
    RUNNING: "നടന്നു കൊണ്ടിരിക്കുന്നത് ",
    PENDING: "തീർപ്പാക്കാത്തത്",
    ENDED: "അവസാനിച്ചു",
    CANCELED: "റദ്ദാക്കി",
    BIDDING_STARTED: "ലേലം ആരംഭിച്ചു",
    HALF_TIME: "പകുതി സമയം കഴിഞ്ഞു",
    ENDING_SOON: "ഉടനെ  അവസാനിക്കും ",
    BIDS: "ലേലങ്ങൾ",
    WON: "ജയിച്ചു",
    active: "സജീവമാണ്",
    inactive: "സജീവമല്ല",
    login_error_1: "ദയവായി സാധുവായ വിശദാംശങ്ങൾ നൽകുക!",
    Required: "*ആവശ്യമാണ്",
    Invalid: "*അസാധുവാണ്",
    Invalid_PhnNumber: "* സാധുവായ ഫോൺ നമ്പർ ആവശ്യമാണ്",
    password_character:
      " * പാസ്സ്‌വേർഡ് 8 പ്രതീകങ്ങളിൽ കൂടുതലായിരിക്കണം, കുറഞ്ഞത് ഒരു ക്യാപിറ്റൽ ലെറ്റെറോ  സംഖ്യയോ പ്രത്യേക പ്രതീകമോ ഉണ്ടായിരിക്കണം",
    password_must: "  * പാസ്സ്‌വേർഡ്  തുല്യമായിരിക്കണം",
    forgot_pwd_msg: "ദയവായി സാധുവായ ഫോൺ നമ്പർ നൽകുക !",
    upload: "ഡോക്യൂമെൻറ് അപ്‌ലോഡ് ചെയ്യുക",
    max: "പരമാവധി ഡ്രൈവർ പരിധി 5 ആണ്",
    added_user: "ഉപയോക്താവിനെ ഡ്രൈവറായി ചേർത്തു",
    unadded_user: "ഉപയോക്താവിനെ ഡ്രൈവറായി തിരഞ്ഞെടുത്തത് മാറ്റുക",
    added: "ഡ്രൈവർമാരെ ചേർത്തു  !",
    something: "എന്തോ കുഴപ്പം സംഭവിച്ചു!",
    driver_message: "ഈ ഡ്രൈവറെ  ഇല്ലാതാക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?",
    acpt_lbl: "അതെ ",
    rjct_lbl: "ഇല്ല ",
    account_message: "നിങ്ങളുടെ അക്കൗണ്ട് ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ??",
    vehicle_message: "ഈ വാഹനം   ഇല്ലാതാക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ??",
    Your: "നിങ്ങളുടെ അവസാന ബിഡ്",
    driver_unavailable: "ഡ്രൈവർ ലഭ്യമല്ല !",
    Change: "ഡ്രൈവറെ മാറ്റുക",
    Add: "ചേർക്കുക",
    signup_succ: "സൈൻഅപ്പ് വിജയിച്ചു",
    confirm: "സ്ഥിരീകരണത്തിനായി ദയവായി 5 സെക്കൻഡ് കാത്തിരിക്കുക!",
    otp_sent: "ഒ ടി പി  വിജയകരമായി അയച്ചു",
    something_wrong: "എന്തോ കുഴപ്പം സംഭവിച്ചു",
    enq_acpt: "അന്വേഷണം സ്വീകരിച്ചു!",
    bid_amt: "ലേലം തുക ആവശ്യമാണ്!",
    enter_amt: "നിലവിലെ ലേലത്തെക്കാൾ കുറഞ്ഞ തുക നൽകുക",
    you_cant: "നിങ്ങൾക്ക് തുടർച്ചയായി ലേലം വിളിക്കാൻ കഴിയില്ല",
    adding_bid: "ലേലം  ചേർക്കുന്നു",
    succ_add: "വിജയകരമായി ചേർത്തു!",
    plz_slct_drvr: "ദയവായി ഡ്രൈവറെ തിരഞ്ഞെടുക്കുക !",
    success: "വിജയിച്ചു ",
    enq_rjct: " അന്വേഷണം നിരസിച്ചു!",
    start_trip: "നിങ്ങൾക്ക് യാത്ര ആരംഭിക്കണമെന്ന് ഉറപ്പാണോ",
    reject_trip: "യാത്ര നിരസിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുവെന്ന് ഉറപ്പാണോ?",
    replace_driver_self:
      "മറ്റൊരു ഡ്രൈവിലേക്ക് യാത്ര അസൈൻ ചെയ്യണമെന്ന് തീർച്ചയാണോ?",
    replace_driver_request:
      "ഡ്രൈവർ മാറ്റിസ്ഥാപിക്കുന്നതിന് അഭ്യർത്ഥിക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?",
    plz_upload: "എല്ലാ രേഖകളും അപ്‌ലോഡ് ചെയ്യുക",
    plz_accpt: "ദയവായി നിബന്ധനകളും വ്യവസ്ഥകളും അംഗീകരിക്കുക",
    rmd_drvr: "ഡ്രൈവറെ  നീക്കം ചെയ്തു",
    dlt_vehicle: "ഈ വാഹനം ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
    rmd_vhcl: "നീക്കം ചെയ്ത വാഹനം",
    prf_updt: "പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്തു",
    prf_pwd_updt: "പാസ്‌വേഡ് അപ്‌ഡേറ്റ് ചെയ്‌തു",
    Driver: "ഡ്രൈവർ",
    Permits: "അനുമതികൾ",
    Agree: "അംഗീകരിക്കുക",
    ok: "ശരി!",
    enquiry_del_msg: "ഈ അന്വേഷണത്തിലേക്ക് ഇനി പ്രവേശിക്കാനാവില്ല .",
    bids: "ലേലങ്ങൾ ഇല്ല ",
    vehicles: "വാഹനങ്ങൾ",
    passwordstr10:
      "നിങ്ങളുടെ പാസ്‌വേഡിന് കുറഞ്ഞത് 8 പ്രതീകങ്ങളെങ്കിലും നീളം ഉണ്ടായിരിക്കണം, അതിൽ കുറഞ്ഞത് ഒരു വലിയക്ഷരം, ഒരു ചെറിയ അക്ഷരം, ഒരു നമ്പർ അക്കം, ഒരു പ്രത്യേക പ്രതീകം എന്നിവ ഉൾപ്പെടുന്നു.",
    passwordstr1:
      "1) നിങ്ങളുടെ പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് 8 പ്രതീകങ്ങൾ അടങ്ങിയിരിക്കണം",
    passwordstr2:
      "2) നിങ്ങളുടെ  പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് ഒരു വലിയക്ഷരമോ വലിയക്ഷരമോ ഉണ്ടായിരിക്കണം (ഉദാ: A, B, മുതലായവ)",
    passwordstr3:
      "3) നിങ്ങളുടെ  പാസ്സ്‌വേർഡിൽ ഒരു ചെറിയ അക്ഷരമെങ്കിലും ഉണ്ടായിരിക്കണം.",
    passwordstr4:
      "4) നിങ്ങളുടെ  പാസ്സ്‌വേർഡിൽ ഒരു അക്കമെങ്കിലും ഉണ്ടായിരിക്കണം (ഉദാ: 0, 1, 2, 3, മുതലായവ)",
    passwordstr5:
      "5) നിങ്ങളുടെ  പാസ്സ്‌വേർഡിൽ കുറഞ്ഞത് ഒരു പ്രത്യേക പ്രതീകമെങ്കിലും അടങ്ങിയിരിക്കണം - ഉദാഹരണത്തിന്: $, #, @, !,%,^,&,*,(,)",
    toggle_active: "നിഷ്ക്രിയമായി",
    toggle_inactive: "സജീവമായി",
    no_driver: "ഡ്രൈവറെ കണ്ടെത്തിയില്ല",
    Profile_Message: "പ്രൊഫൈൽ വിജയകരമായി പൂർത്തിയാക്കി",
    loginPage: {
      phoneRequired: "ദയവായി മൊബൈൽ നമ്പർ നൽകുക",
      phoneInvalid: "ദയവായി സാധുവായ മൊബൈൽ നമ്പർ നൽകുക",
      passwordRequired: "ദയവായി പാസ്സ്‌വേർഡ് നൽകുക",
      passwordMinLength: "പാസ്സ്‌വേർഡ് 4 പ്രതീകങ്ങളിൽ കുറവായിരിക്കരുത്"
    },
    addVehicleMessage: "വാഹനം വിജയകരമായി ചേർത്തു",
    noti_label: "അറിയിപ്പ്",
    adddriver_success: "ഡ്രൈവർ അസൈൻ ചെയ്‌തു",
    tripStatus: {
      readyMessage: "ഡ്രൈവർ പിക്കപ്പിന് തയ്യാറാണ്.",
      startMessage: "ഡ്രൈവർ യാത്ര തുടങ്ങി.",
      endMessage: "ഡ്രൈവർ യാത്ര അവസാനിപ്പിച്ചു.",
    },
    payment_status: {
      success_message: "പേയ്‌മെന്റ് വിജയം",
      error_message: "പേയ്‌മെന്റ് പരാജയപ്പെട്ടു",
    },
    editProfile: {
      email_invalid: "ദയവായി സാധുവായ ഇമെയിൽ ഐഡി നൽകുക",
    },
    pendingProfileMessage:
      "നിങ്ങളുടെ ഐഡി സ്ഥിരീകരണ രേഖകൾ നിലവിൽ അവലോകനം തീർപ്പാക്കിയിട്ടില്ല. നിങ്ങളുടെ ക്ഷമയെ ഞങ്ങൾ അഭിനന്ദിക്കുന്നു, പ്രക്രിയ പൂർത്തിയായാൽ നിങ്ങളെ അറിയിക്കും.",
    rejectProfileMessage:
      "നിങ്ങളുടെ ഐഡി സ്ഥിരീകരണ രേഖകൾ നിരസിക്കപ്പെട്ടുവെന്ന് നിങ്ങളെ അറിയിക്കുന്നതിൽ ഞങ്ങൾ ഖേദിക്കുന്നു. വിജയകരമായ സ്ഥിരീകരണത്തിനായി ഞങ്ങളുടെ മാർഗ്ഗനിർദ്ദേശങ്ങൾ പിന്തുടർന്ന് അവ അവലോകനം ചെയ്‌ത് വീണ്ടും സമർപ്പിക്കുക. ഏത് സഹായത്തിനും ഞങ്ങളെ ബന്ധപ്പെടുക.",
    profileStatusTitle: "ഡോക്യുമെൻറ് സ്റ്റാറ്റസ് ",
    TRIPS: "യാത്രകൾ",
  },
};
